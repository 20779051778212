.wrapper {
    @apply flex flex-row flex-wrap py-4;

    .link {
        @apply text-gray-500 capitalize;
    }

    span {
        @apply px-1 text-gray-500 capitalize;

        &:last-child {
            @apply text-black;
        }
    }

}
