.wrapper {
    @apply bg-white h-auto w-auto flex flex-col gap-4 justify-center items-center p-8 rounded-xl shadow-xl;

    h3 {
        @apply text-secondary-500;
    }

    > div {
        @apply w-full h-auto;

        > span {
            @apply text-red-500 text-xs;
        }
    }
}
