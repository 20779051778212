.overlay {
    @apply fixed top-0 left-0 flex justify-center items-center p-8;
    background: rgba(255, 255, 255, 0.6);
    width: 100vw;
    height: 100vh;
    z-index: 9999;

    .overlayInner {
        @apply w-full h-full p-6 relative flex;
        background-color: #c8c887;
        color: #070707;
        box-shadow: 0 0 50px 0 rgba(50, 50, 50, 0.35);
        max-width: 60vw;
        max-height: 60vh;
    }

    .close {
        @apply absolute top-0 right-0 w-8 h-8 bg-white rounded-full flex justify-center items-center cursor-pointer;
        transform: translate(50%, -50%);
        box-shadow: 0 0 50px 0 rgba(50, 50, 50, 0.35);
    }

    .body {
        @apply overflow-y-scroll overflow-x-hidden w-full h-full;
    }

    .cols {
        @apply flex -mx-4;
    }

    .col {
        @apply w-1/2 px-4;
    }

    .colFull {
        @apply w-full;
    }

    ul {
        @apply list-outside pl-6;

        ul {
            list-style-type: circle;
        }
    }

    //table {
       // color: #070707;

        table {
            //background: #e1e1a9;
            color: #070707;

            p:last-child {
                margin-bottom: 0;
            }

            td {
                vertical-align: top;
            }

            //tbody {
            //    tr:first-child {
            //        td:first-child {
            //            background: #D7D88D;
            //            color: #070707;
            //        }
            //    }
            //}

            thead {
                background: #999964;
                color: #e1e1a9;
            }

        }

    //}

}
