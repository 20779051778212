.wrapper {
    @apply flex flex-col;

    h3 {
        @apply text-primary-900;
    }

    > .slider {
        @apply flex flex-row gap-2 mt-4 mb-8;

        > .content {
            @apply flex flex-col w-full h-full gap-8;
            @apply xl:flex-row;

            > div:first-child {
                @apply w-full h-full flex flex-row flex-wrap gap-4;

                img {
                    @apply w-auto h-auto bg-center bg-no-repeat bg-cover rounded-lg mx-auto;
                }
            }

            > div:last-child {
                @apply w-full h-full flex flex-col justify-center;
                @apply xl:w-9/12;
            }
        }

        > button {
            @apply h-10 w-16 rounded-lg bg-secondary-200 flex justify-center items-center;

            > svg {
                @apply text-secondary-500;
            }

            &:disabled {
                @apply bg-gray-200 cursor-not-allowed;

                > svg {
                    @apply text-gray-500;
                }
            }
        }
    }
}
