.wrapper {
    @apply relative w-full;
    min-height: 100vh;

    > .status {
        @apply absolute left-0 bottom-5 w-auto;
        @apply xl:bottom-20;

        > .video {
            @apply relative w-auto h-auto bg-black mb-4;

            > video {
                @apply rounded-lg;
                @apply sm:h-60;
                @apply lg:h-64;
                @apply xl:h-80;
            }

            > button {
                @apply absolute top-1/2 left-1/2 bg-transparent shadow-md w-11/12 flex justify-center items-center z-[2];
                @apply lg:w-auto;
                transform: translate(-50%, -50%);
            }
        }
    }
}
