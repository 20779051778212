.wrapper {
    @apply bg-transparent text-white w-full h-full p-1
        flex justify-center items-center cursor-pointer;
    height: 112px;

    &:hover {
        > .overlay {
            @apply opacity-100 visible;
        }
    }
}

.complete {
    background: #4f772d;

    path {
        fill: #86c84e;
    }

    &:hover {
        background: #6ba23c;

        path {
            fill: #86c84e;
        }
    }
}

.inprogress {
    background: #4f772d;

    path {
        fill: #86c84e;
    }

    &:hover {
        background: #6ba23c;

        path {
            fill: #86c84e;
        }
    }
}

//.inprogress {
//    background: #FFB731;
//
//    path {
//        fill: #FFc75f;
//    }
//
//    &:hover {
//        background: #FFc75f;
//
//        path {
//            fill: #FFB731;
//        }
//    }
//}


.behind {
    background: #FFB731;

    path {
        fill: #FFc75f;
    }

    &:hover {
        background: #FFc75f;

        path {
            fill: #FFB731;
        }
    }
}

//.behind {
//    background: #f46231;
//
//    path {
//        fill: #ffd1a6;
//    }
//
//    &:hover {
//        background: #ff845a;
//
//        path {
//            fill: #ffd1a6;
//        }
//    }
//}


.notstarted {
    background: #c8c887;

    path {
        fill: #d8d895;
    }

    &:hover {
        background: #c8c887;

        path {
            fill: #d8d895;
        }
    }
}
