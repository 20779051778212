.wrapper {
    @apply flex flex-col;

    > .slider {
        @apply flex flex-row gap-2 mt-4 mb-8;

        > .content {
            @apply w-full h-full block;

            //> div {
            //    @apply grid grid-cols-1 gap-4;
            //    @apply xl:grid-cols-2;
            //}
        }

        > button {
            @apply h-10 w-16 rounded-lg bg-secondary-200 flex justify-center items-center;

            > svg {
                @apply text-secondary-500;
            }

            &:disabled {
                @apply bg-gray-200 cursor-not-allowed;

                > svg {
                    @apply text-gray-500;
                }
            }
        }
    }
}
