@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");
@import url("react-multi-carousel/lib/styles.css");

body {
    margin: 0;
    @apply font-primary;
    overflow-y: scroll;
    overflow-x: hidden;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: transparent;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

@layer base {
    h1 {
        @apply mb-2 text-3xl font-black text-gray-700 uppercase;
    }

    h2 {
        @apply mb-2 text-2xl font-black uppercase text-secondary-500;
    }

    h3 {
        @apply mb-2 text-xl font-bold text-white uppercase;
    }

    h4 {
        @apply text-lg font-bold text-white uppercase;
    }

    h5 {
        @apply text-base font-bold text-white uppercase;
    }

    h6 {
        @apply text-base font-bold text-gray-700 capitalize;
    }

    p {
        @apply mb-6;
    }

    ul, ol {
        @apply mb-6;
    }

    main ul {
        @apply list-disc list-inside;
    }

    main ol {
        @apply list-decimal list-inside;
    }


    .react-multi-carousel-item ol ol {
        @apply pl-5;
    }


    li {
        @apply mb-2;
    }

    strong {
        @apply font-bold;
    }

    input {
        @apply w-full p-3 text-left border-2 border-gray-400 shadow-sm outline-none rounded-xl bg-gray-50;
        box-sizing: border-box;
    }

    input:focus {
        @apply border-gray-500;
    }


    table {
        @apply w-full;
        max-width: 100%;
    }


    td, th {
        @apply py-1 px-2;
    }

    table img {
        max-width: 100%;
    }

    .html-text {

    }

    .html-text table {
        background: #e1e1a9;
        color: #070707;
    }

    .html-text table p:last-child {
        margin-bottom: 0;
    }

    .html-text tr:nth-child(even) {
        background: #D8D895;
    }

    .html-text tr:first-child {
        background: #999964;
        color: #e1e1a9;
        text-transform: uppercase;
        font-weight: bold;
    }

}

@layer base {
    input {
        @apply w-full p-3 text-left border-2 border-gray-400 shadow-sm outline-none rounded-xl bg-gray-50;
        box-sizing: border-box;
    }

    input:focus {
        @apply border-gray-500;
    }
}

@layer components {
    .btn-primary {
        @apply w-56 px-5 py-4 text-base font-semibold text-white bg-primary-900 rounded-xl hover:opacity-90;
    }
}


.slide-container ul ul {
    @apply pl-8 mb-0 list-outside;
    list-style-type: circle;
}

.slide-container ul ul li:last-child {
    @apply mb-0;
}

.slide-container ol ol {
    @apply pl-8 mb-0 list-outside;
}


.slide-container ol ol li:last-child {
    @apply mb-0;
}

