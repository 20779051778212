.content {
    @apply block w-full h-auto;

    > div {
        @apply w-full block;

        ul {
            @apply list-inside list-disc text-gray-800;
        }
    }
}
