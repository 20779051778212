.wrapper {
    @apply relative;

    .carousal {
        @apply z-10 w-full mx-auto md:w-10/12 lg:w-11/12 2xl:w-full;
    }
}

.slideItem {
    min-height: calc(100vh - 171px);
    min-height: 600px;
}

.buttonWrapper {
    @apply relative bottom-0 left-0 right-0 flex justify-between z-0 h-16;
    @apply md:absolute md:top-0 md:-left-8 md:-right-8;
    @apply lg:top-80;

    > .prev {
        @apply uppercase flex flex-col items-center;

        > button {
            @apply flex items-center justify-center w-16 h-10 rounded-md bg-secondary-200;

            > svg {
                @apply text-secondary-500;
            }
        }

        > span {
            @apply block pt-1 w-full uppercase text-center text-sm font-semibold;
        }

        &:hover {
            @apply text-primary-500;

            > button {
                @apply bg-primary-400;

                > svg {
                    @apply text-primary-500;
                }
            }
        }
    }

    .next {
        @apply uppercase flex flex-col items-center;

        > button {
            @apply flex items-center justify-center w-16 h-10 rounded-md bg-secondary-200;

            > svg {
                @apply text-secondary-500;
            }
        }

        > span {
            @apply block pt-1 w-full uppercase text-center text-sm font-semibold;
        }

        &:hover {
            @apply text-primary-500;

            > button {
                @apply bg-primary-400;

                > svg {
                    @apply text-primary-500;
                }
            }
        }
    }

    @screen 2xl {

        > .prev {
            transform: translateX(-100%);
        }

        > .next {
            transform: translateX(100%);
        }

    }

}
