.wrapper {
    @apply flex flex-col;

    > h3 {
        @apply text-primary-900;
    }

    > .slider {
        @apply flex flex-row gap-2 mt-4;

        > .table {
            @apply w-full h-auto flex flex-col overflow-x-auto;
            // @apply xl:max-h-[30rem];

            > div {
                @apply mb-2;
            }
        }

        > button {
            @apply h-10 w-16 rounded-lg bg-secondary-200 flex justify-center items-center;

            > svg {
                @apply text-secondary-500;
            }

            &:disabled {
                @apply bg-gray-200 cursor-not-allowed;

                > svg {
                    @apply text-gray-500;
                }
            }
        }
    }
}
