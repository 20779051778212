.wrapper {
    @apply absolute top-0 left-0 right-0;
    @apply bg-primary-900 pt-4 z-50;
    @apply xl:pt-8;

    .cover {
        @apply flex flex-row justify-between relative;
        @apply xl:justify-start;

        .logo {
            @apply w-auto text-secondary-500 pr-12 mb-5 border-r-2 border-secondary-500;
        }

        .list {
            @apply hidden w-full flex-row pl-12 mb-0;
            @apply xl:flex;

            .listItem {
                @apply px-5 text-center pt-4 mx-2 text-secondary-500 font-bold text-lg cursor-pointer uppercase;
                // width: 320px;

                .dropdown {
                    @apply bg-primary-400 h-auto p-10 pb-0 max-w-screen-xl mx-auto shadow-xl overflow-auto;
                    @apply opacity-0 invisible left-0;
                    @apply absolute w-full;
                    top: 100%;
                }

                &.active {
                    @apply bg-primary-400 rounded-t-md h-full;
                    .dropdown {
                        @apply opacity-100 visible;
                    }
                }
            }
        }

        .hamburger {
            @apply cursor-pointer flex items-center justify-center pb-5;
            @apply xl:hidden;

            svg {
                @apply text-secondary-500;
            }
        }
    }

    .mobileDropdown {
        @apply hidden bg-primary-400 absolute left-0 w-full;
    }

    .mobileDropdownActive {
        @apply block bg-primary-400 absolute left-0 w-full;
        @apply xl:hidden;

    }

}

.wrapperRelative {

}
