.tableWrapper {
    @apply block overflow-hidden;
    background: #e1e1a9;

    > table {
        @apply w-full;
        border-left: 1px solid #999964;
        border-top: 1px solid #999964;
        color: #070707;

        table {
            border-top: 0;
            border-left: 0;
            color: #070707;

            td, th {
                vertical-align: top;

                &:last-child {
                    border-right: 0;
                }
            }

            tr:first-child {
                @apply bg-transparent;
                color: #070707;
            }

            tr:last-child {
                td {
                    border-bottom: 0;
                }
            }
        }

        th, td {
            @apply p-2;
            box-sizing: border-box;
            border-right: 1px solid #999964;
            border-bottom: 1px solid #999964;

            svg {
                @apply w-full h-auto;
            }
        }

        th {
            @apply py-1 text-sm;
        }

        > tr:first-child {
            background: #999964;
            color: #e1e1a9;

            > th {
                @apply py-1 border-none text-base uppercase;
                border-color: #999964;
            }
        }

        > tr {

            > td {
                @apply border-x text-left p-2 align-top;
                border-color: #999964;
            }
        }
    }

    .noPadding {
        @apply p-0;
    }

    .noPaddingX {
        padding-left: 0 !important;
        padding-right: 0 !important;
        padding: 0 !important;
    }

    .quarterCell {
        @apply px-1 text-center relative;
        width: 40px;
    }

    .milestone {
        width: 200px;
    }

    .popupTrigger {
        @apply cursor-pointer;

        &:hover {
            color: #c25d00;
        }
    }
}

.wrapper {
    @apply flex flex-col justify-center items-center gap-8;
    @apply xl:flex-row;

    .cols {
        @apply flex -mx-4 py-5;
    }

    .col {
        @apply px-4 w-1/2;
    }

    .colFull {
        @apply w-full;
    }
}
